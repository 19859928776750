/* ----------------------------------------------------------- */
/* == config */
/* ----------------------------------------------------------- */

:root {
  --main-bg-color: brown;
}

/* base
-------------------------------------------------------------- */

:root {
  --base-font: 16; /* px value (without unit), will be converted in em */
  --line-height: 1.5;
  --fontstack: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* focus ring
-------------------------------------------------------------- */

:root {
  --focus-ring: 0 0 0 .2rem rgba(221, 40, 211, .5);
}
