/* ----------------------------------------------------------- */
/* == width */
/* ----------------------------------------------------------- */

.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.w33 {
  width: 33.3333%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w66 {
  width: 66.6666%;
}
.w70 {
  width: 70%;
}
.w75 {
  width: 75%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}
