/* ----------------------------------------------------------- */
/* == Welcome screen */
/* ----------------------------------------------------------- */

.welcome {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem;
}

.welcome-about {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.welcome-title {
  margin: 0;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.3em;
}

.welcome-illu {
  display: inline-flex;
  justify-content: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
  max-width: calc(100vw - 4rem);
  max-height: 30rem;
  width: auto;
  height: auto;
}

.welcome-illu svg {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.welcome-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-bottom: 2rem;
  color: rgba(255, 255, 255, .7);
  font-size: 1.4rem;
}

.welcome-steps__item {
  margin: .5rem 0;
}
