/* ----------------------------------------------------------- */
/* == rwd -> large */
/* ----------------------------------------------------------- */

@media (orientation: landscape) and (max-height: 450px) {
  /* layout
  -------------------------------------------------------------- */

  .layout {
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-right: 0;
    margin-bottom: auto;
    margin-left: 0;
    max-width: none;
    max-height: calc(100% - 2rem);
    width: 100%;
  }

  /* Welcome screen
  -------------------------------------------------------------- */

  .welcome {
    align-items: center;
  }

  .welcome-illu {
    max-height: 50vh;
  }

  .welcome-about {
    margin-bottom: 2rem;
  }

  /* Capture screen
  -------------------------------------------------------------- */

  .capture-body {
    position: fixed;
    right: 0;
    left: 0;
    overflow-y: hidden;
  }

  .capture {
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
  }

  .capture-progress {
    align-self: center;
  }

  .capture-actions {
    flex-direction: column;
    flex-shrink: .0;
    margin: 0 auto;
  }

  .capture-switch,
  .capture-switch:visited {
    position: absolute;
    top: 0;
    right: calc(50% - 2.5rem);
  }

  /* Options
  -------------------------------------------------------------- */

  .options {
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    width: 11.5rem;
  }

  .options__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .options__btn svg {
    margin-right: 0;
    margin-bottom: .5rem;
  }

  /* Option panel */

  .options__panel {
    top: 0;
    right: 1rem;
    bottom: 0;
    left: 1rem;
    flex-direction: column;
    width: auto;
    transform: translateX(-12rem) translateY(0);
  }

  .option__panelOption {
    width: 100%;
    height: 50%;
  }

  /* Progress bar
  -------------------------------------------------------------- */

  .progressBar {
    margin-right: 1rem;
    margin-left: 1rem;
    width: 9.5rem;
    height: auto;
  }

  /* Preview
  -------------------------------------------------------------- */

  .previewPage {
    flex-direction: row;
    margin-left: 0;
    padding-right: 2rem;
  }

  .preview {
    flex-shrink: 0;
    margin-right: 2rem;
    margin-bottom: 0;
    max-width: 80vh;
  }

  /* Download
  -------------------------------------------------------------- */

  .download {
    padding-bottom: 4rem;
  }

  .download-btn,
  .download-btn:visited {
    margin: auto 0;
    margin-right: auto;
    margin-left: auto;
    width: auto;
  }
}
