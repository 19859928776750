/* ----------------------------------------------------------- */
/* == Encoding overlay */
/* ----------------------------------------------------------- */

.encoding {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4rem;
  text-align: center;
}

/* Loader
-------------------------------------------------------------- */

.encoding-loader {
  position: relative;
  flex-shrink: 0;
  margin-bottom: 1rem;
  line-height: 1;
}

.encoding-loader__preview {
  position: absolute;
  top: 1.4rem;
  left: 1.4rem;
  overflow: hidden;
  width: 11.4rem;
  height: 11.4rem;
  border-radius: 100%;

  object-fit: cover;
}

/* Progress bar
-------------------------------------------------------------- */

.encoding-progress__value {
  transition: stroke-dashoffset .2s ease-in-out;

  will-change: auto;
  stroke-miterlimit: round;
}

.encoding-percent {
  padding-bottom: 2rem;
  font-size: 5rem;
  line-height: 1;
}

/* Percent
-------------------------------------------------------------- */

.encoding-percent {
  color: rgba(255, 255, 255, .5);
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8rem;
}

/* Notif
-------------------------------------------------------------- */

.encoding-notif,
.encoding-notif:visited {
  margin-top: auto;
  padding: .5rem 2rem;
  border: none;
  border-radius: .3rem;
  background-color: transparent;
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
  cursor: pointer;
}

.encoding-notif:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.encoding-notif
.encoding-notif__icon {
  margin-bottom: .7rem;
}
