/* ----------------------------------------------------------- */
/* == typography */
/* ----------------------------------------------------------- */

html {
  font-size: 62.5%;
  /* IE9-11 calculation fix */
  font-size: calc(1em * .625);
}

body {
  font-size: 1.6em;
  line-height: 1.5;
}

p,
ul,
ol,
dl,
blockquote,
pre,
td,
th,
label,
textarea,
caption {
  margin: 0 0 1.5em 0;
}
