/* ----------------------------------------------------------- */
/* == rwd -> medium */
/* ----------------------------------------------------------- */

@media (min-width: 960px) {
  /* Layout
  -------------------------------------------------------------- */

  .layout {
    width: 40rem;
  }

  /* Welcome
  -------------------------------------------------------------- */

  .welcome-illu {
    margin-top: 4rem;
  }
}
