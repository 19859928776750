/* ----------------------------------------------------------- */
/* == Capture screen */
/* ----------------------------------------------------------- */

.capture {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
}

/* Progress bar
-------------------------------------------------------------- */

.capture-progress {
  display: flex;
  align-items: center;
  height: 11rem;
}

/* Capture actions
-------------------------------------------------------------- */

.capture-actions {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 4rem;
  min-height: 8rem;
}

/* Capture button
-------------------------------------------------------------- */

.capture-btn,
.capture-btn:visited {
  position: relative;
  padding: 0;
  width: 8rem;
  height: 8rem;
  border: none;
  border-radius: 8rem;
  background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-secondary) 100%);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, .25);
  font-size: 0;
  cursor: pointer;
}

.capture-btn:active {
  background: linear-gradient(135deg, var(--color-secondary) 0%, var(--color-primary) 100%);
  box-shadow: none;
}

.capture-btn:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.capture-btn:disabled {
  background: grey;
  opacity: .2;
  cursor: not-allowed;
}

/* Capturing state */

.capture-btn--capturing,
.capture-btn--capturing:visited,
.capture-btn--capturing:active {
  position: relative;
  background: linear-gradient(135deg, #212045 0%, #3633a0 100%);
  cursor: not-allowed;
}

.capture-btn--capturing::after {
  position: absolute;
  top: calc(50% - (6rem / 2));
  left: calc(50% - (6rem / 2));
  display: block;
  width: 6rem;
  height: 6rem;
  border: .2rem solid transparent;
  border-color: transparent transparent rgba(255, 255, 255, .8) rgba(255, 255, 255, .8);
  border-radius: 42rem;
  content: "";
  animation: captureSpinAround 1000ms infinite linear;
}

@keyframes captureSpinAround {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/* Switch button
-------------------------------------------------------------- */

.capture-switch,
.capture-switch:visited {
  position: absolute;
  top: calc(50% - 2.5rem);
  right: 0;
  display: block;
  padding: 1rem;
  border: none;
  border-radius: .2rem;
  background-color: transparent;
  color: #fff;
  line-height: 0;
}

.capture-switch:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.capture-switch svg {
  width: 3rem;
  height: 3rem;
}

/* Timer overlay
-------------------------------------------------------------- */

.capture-timer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  text-shadow: 0 0 1rem var(--color-tertiary);
  font-weight: bold;
  font-size: 10rem;
  transform-origin: center center;
  animation: 1s ease-in infinite forwards counterFlash;
}

@keyframes counterFlash {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  30% {
    opacity: 1;
    transform: scale(1);
  }
  97% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
