/* ----------------------------------------------------------- */
/* == Refresh banner */
/* ----------------------------------------------------------- */

.refreshBanner {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem 4rem;
  background-color: #42b983;
  color: #fff;
  text-align: center;
  font-size: 1.6rem;
  transition: .15s all;
  transform: translateY(103%);
}

.refreshBanner.active {
  transform: translateY(0);
}

.refreshBanner__btn,
.refreshBanner__btn:visited {
  display: inline-block;
  padding: .5rem 2.5rem;
  border: .1rem solid #fff;
  border-radius: 4.2rem;
  background: transparent;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

.refreshBanner__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  border: none;
  background: none;
  color: #fff;
  line-height: 1;
  cursor: pointer;
}
