/**
* Button Module
* namespace : .btn
*/

/* ----------------------------------------------------------- */
/* == configuration */
/* ----------------------------------------------------------- */

:root {
  --btn-primary: var(--color-primary);
  --btn-secondary: var(--color-secondary);
  --btn-radius: 4.2rem;
}

/* ----------------------------------------------------------- */
/* == module */
/* ----------------------------------------------------------- */

.btn {
  display: inline-block;
  padding: .8em 1.6em .9em;
  border: none;
  border-radius: var(--btn-radius);
  background-color: var(--btn-primary);
  box-shadow: none;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  text-decoration: none !important;
  line-height: normal;
  cursor: pointer;
  transition: background .4s;
}

.btn:active,
.btn:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

/* colors
-------------------------------------------------------------- */

.btn--primary,
.btn--primary:link,
.btn--primary:visited {
  background: linear-gradient(116.15deg, var(--btn-primary) 0%, var(--btn-secondary) 100%);
  background-color: var(--btn-primary);
  color: #fff;
}

.btn--primary:hover,
.btn--primary:active,
.btn--primary:focus {
  background: var(--btn-primary);
}

.btn--danger,
.btn--danger:link,
.btn--danger:visited {
  background: linear-gradient(116.15deg, #dd2828 0%, #a72020 100%);
  font-weight: bold;
}

.btn--danger:hover,
.btn--danger:active,
.btn--danger:focus {
  background: linear-gradient(116.15deg, #dd2828 0%, #a72020 100%);
}

/* Loading state
-------------------------------------------------------------- */

.btn--loading,
.btn--loading:link,
.btn--loading:visited {
  position: relative;
  color: transparent;
  pointer-events: none;
}

.btn--loading svg {
  display: none;
}

.btn--loading::after {
  position: absolute;
  top: calc(50% - (2em / 2));
  left: calc(50% - (2em / 2));
  display: block;
  width: 2em;
  height: 2em;
  border: .2rem solid transparent;
  border-color: transparent transparent #fff #fff;
  border-radius: 42rem;
  content: "";
  animation: spinAround 500ms infinite linear;
}

@keyframes spinAround {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}


/* size
-------------------------------------------------------------- */

.btn--big {
  font-size: calc(var(--base-font) + .4em);
}
