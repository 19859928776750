/* ----------------------------------------------------------- */
/* == Options bar module */
/* ----------------------------------------------------------- */


/* Options
-------------------------------------------------------------- */

.options {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-right: -.2rem;
  margin-left: -.2rem;
  padding: 2.2rem .2rem;
}

.options__select,
.options__btn {
  appearance: none;
  margin: 0;
  padding: 2rem;
  border: .1rem solid transparent;
  border-radius: .3rem;
  background: transparent;
  color: #fff;
  line-height: 2.2rem;
  cursor: pointer;
}

.options__select:focus,
.options__btn:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.options__select {
  padding-right: 4.4rem;
  background: url("/assets/img/ico-arrow.svg") no-repeat;
  background-position: calc(100% - 2rem) center;
}

.options__btn svg {
  vertical-align: middle;
  line-height: 1;
}

.options__btn--check {
  position: relative;
}

.options__btn--check::after {
  position: absolute;
  right: 1.8rem;
  bottom: 1.5rem;
  width: 1.8rem;
  height: 1.5rem;
  background: url("/assets/img/boomerang-check.svg");
  content: "";
  animation: btnOptionArrive .5s cubic-bezier(.18,.89,.32,1.28);
  animation-delay: 100ms;
}

.options__btn--timer {
  position: relative;
}

.options__btn--timer::after {
  position: absolute;
  right: 1.8rem;
  bottom: 1.6rem;
  color: #fff;
  content: attr(data-timer);
  text-align: right;
  text-shadow: .2rem 0 0 var(--color-tertiary), -.2rem 0 0 var(--color-tertiary), 0 .2rem 0 var(--color-tertiary), 0 -.2rem 0 var(--color-tertiary), 1px 1px var(--color-tertiary), -1px -1px 0 var(--color-tertiary), 1px -1px 0 var(--color-tertiary), -1px 1px 0 var(--color-tertiary);
  font-weight: 600;
  font-size: 1.3rem;
  line-height: normal;
  animation: btnOptionArrive .5s cubic-bezier(.18,.89,.32,1.28);
}

@keyframes btnOptionArrive {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}
/* Panel
-------------------------------------------------------------- */

.options__panel {
  position: absolute;
  top: 2.2rem;
  bottom: 2.2rem;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  border-radius: .3rem;
  background-color: #4c4981;
  transition: 300ms transform;
  transform: translateY(-9rem);
}

.options__panel.active {
  transform: translate(0);
}

.option__panelOption,
.option__panelOption:visited {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 50%;
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

.option__panelOption--big {
  font-size: 1.6rem;
}

.option__panelOption.current {
  background-color: var(--color-primary);
}

.option__panelOption svg {
  margin-bottom: .6rem;
  max-height: 1.6rem;
}
