/* ----------------------------------------------------------- */
/* == colors */
/* ----------------------------------------------------------- */

/* palette
-------------------------------------------------------------- */

:root {
  --color-primary: #dd28d3;
  --color-secondary: #8420a7;
  --color-tertiary: #212045;
  --color-info: #2185d0;
}

/* texts
-------------------------------------------------------------- */

:root {
  --color-light-text: #fcfcfc;
  --color-dark-text: var(--color-tertiary);
}

/* links
-------------------------------------------------------------- */

:root {
  --color-link: var(--color-primary);
}
