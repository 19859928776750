/* ----------------------------------------------------------- */
/* == rwd -> small */
/* ----------------------------------------------------------- */

@media (min-width: 768px) {
  .souvenir {
    flex-grow: inherit;
  }

  /* Overlay layout
  -------------------------------------------------------------- */

  .layoutOverlay-body {
    background: var(--color-tertiary);
  }

  .layoutOverlay-close {
    top: 1rem;
    right: 1rem;
  }

  .layoutOverlay-close svg {
    width: 8rem;
    height: 8rem;
  }

  /* Welcome screen
  -------------------------------------------------------------- */

  .welcome {
    justify-content: center;
  }

  .welcome-about {
    margin-top: 0;
    margin-bottom: 4rem;
  }

  .welcome-illu {
    margin-top: -1.5rem;
    margin-bottom: 4rem;
  }

  .welcome-steps {
    font-size: 1.6rem;
  }

  /* Capture screen
  -------------------------------------------------------------- */

  .capture-btn,
  .capture-btn:visited {
    margin-top: 0;
  }

  /* Encoding screen
  -------------------------------------------------------------- */

  .encoding-notif {
    margin-top: 7rem;
  }


}
