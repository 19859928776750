/* ----------------------------------------------------------- */
/* == Preview module */
/* ----------------------------------------------------------- */

.preview {
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
  max-width: 100%;
  width: 35rem;
  border-radius: .5rem;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 .4rem 5rem rgba(0, 0, 0, .5);
}

.preview::after {
  position: absolute;
  top: calc(50% - (3em / 2));
  left: calc(50% - (3em / 2));
  z-index: -1;
  display: block;
  width: 3em;
  height: 3em;
  border: .2rem solid transparent;
  border-color: transparent transparent rgba(255, 255, 255, .8) rgba(255, 255, 255, .8);
  border-radius: 42rem;
  content: "";
  animation: previewSpinAround 1200ms infinite linear;
}

@keyframes previewSpinAround {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.preview::before {
  display: block;
  padding-top: 100%;
  width: 100%;
  content: "";
}

.preview--novideo::before,
.preview--novideo::after {
  content: none;
}

.preview--flip {
  transform: scaleX(-1);
}

.preview-visual {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: .5rem;

  object-fit: cover;
}

.preview-visualImg {
  width: 100%;
}
